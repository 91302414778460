console.log("sms script is running 2");

function onSmsClick(sms_rec_id,sms_module,sms_mob,sms_name)
{
    
$("#mySMSModal").remove();

var myKeyVals = { id : sms_rec_id, module : capitalizeFirstLetter(sms_module), mob : sms_mob,name : sms_name};

jQuery.ajax({

    url : 'index.php?entryPoint=SMSIntegrationController',
    type : 'POST',
    data: myKeyVals,
    success : function (result) {
    console.log(result);
    $("#sms_icon").after('<div id="mySMSModal" class="modal fade right" data-bs-backdrop="static" data-bs-keyboard="false"></div>');

    $("#mySMSModal").html(result); 
    $("#mySMSModal").modal('show');
    $(".modal-backdrop").hide();
    // $(".modal-dialog").draggable();
       sms_chat(sms_mob);
       get_sms_templates(sms_module);
      const chat_show_timer = window.setInterval(function() {
         sms_chat(sms_mob);
      }, 7000);

        $(".close").click(function()
        {
        $("#mySMSModal").html("");
        $("#mySMSModal").modal('hide');
        clearInterval(chat_show_timer);
        });

    },
    error : function () {
        console.log ('error');
    }

    });
      
    
}

function capitalizeFirstLetter(string){
   return string.charAt(0).toUpperCase() + string.slice(1);
} 
function sms_chat(sms_mob)
{
   jQuery.ajax({
  
      url : 'index.php?entryPoint=SmsChatIntegrationController',
      type : 'POST',
      async: true,
      data: { mob: sms_mob},
      success : function (res) {
          if(res)
          {
             $("#msg_card_body").html(res);
          }
          else
          {
             $("#msg_card_body").html("No data found");
          }
      },
      error : function () {
         console.log ('error');
      }
 
    });
}



function get_sms_templates(sms_module)
{
   jQuery.ajax({
  
      url : 'index.php?entryPoint=SmsGetTemplateController',
      type : 'POST',
      async: true,
      data: { module_name: sms_module},
      success : function (res) 
      {
         $("#template_dropdown").html(res);
         
      },
      error : function () {
         console.log ('error');
      }
 
    });
}
// Function to add the Bulk SMS option to the dropdown
function addBulkSmsOption() {
    // Create the new dropdown item
    console.log("called addBulkSmsOption");
    const bulkSmsItem = document.createElement('a');
    bulkSmsItem.setAttribute('ngbdropdownitem', '');
    bulkSmsItem.className = 'dropdown-item bulk-sms-action ng-star-inserted';
    bulkSmsItem.setAttribute('tabindex', '0');

    // Create the inner structure
    const divFlex = document.createElement('div');
    divFlex.className = 'd-flex align-items-center';

    const divLabel = document.createElement('div');
    divLabel.className = 'dropdown-item-label flex-grow-1';

    const scrmLabel = document.createElement('scrm-label');
    scrmLabel.className = 'ng-star-inserted';
    scrmLabel.textContent = 'Bulk SMS';

    // Append elements
    divLabel.appendChild(scrmLabel);
    divFlex.appendChild(divLabel);
    bulkSmsItem.appendChild(divFlex);

    // Append the new item to the dropdown menu
    const dropdownMenu = document.querySelector('.dropdown-menu'); // Adjust the selector as necessary
    dropdownMenu.appendChild(bulkSmsItem);
}

// Call the function to add the option
// addBulkSmsOption();